<template>
  <div
    v-if="view"
    class="text-truncate"
    v-text="sSelected ? sSelected.text : ''"
  />
  <v-select
    v-else
    v-model="sValue"
    :disabled="disabled"
    :items="items"
    :menu-props="{ offsetY: true }"
    dense
    hide-details
    outlined
  />
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { find } from "lodash";

@Component
export default class AccountingCreditDebitSelect extends Vue {
  @VModel({ type: String }) sValue!: string;
  @Prop(Boolean) readonly view!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;

  get items() {
    return [
      { text: this.$t("accounting.none"), value: null },

      // GENERAL
      { text: this.$t("accounting.net"), value: "net" }, // NETO
      { text: this.$t("accounting.subtotal"), value: "total_without_tax" }, // SUBTOTAL
      { text: this.$t("accounting.tax"), value: "tax_value" }, // TOTAL IMPUESTOS
      { text: this.$t("accounting.total"), value: "total_with_tax" }, // TOTAL
      { text: this.$t("accounting.rounding"), value: "rounding" }, // REDONDEO

      // BY PRODUCT
      { text: this.$t("accounting.product.net"), value: "product_net" }, // NETO
      {
        text: this.$t("accounting.product.subtotal"),
        value: "product_total_without_tax",
      },

      // SUBTOTAL
      { text: this.$t("accounting.product.tax"), value: "product_tax_value" }, // TOTAL IMPUESTOS
      {
        text: this.$t("accounting.product.total"),
        value: "product_total_with_tax",
      }, // TOTAL

      // PRODUCT DISCOUNTS
      {
        text: this.$t("accounting.product.discount.net"),
        value: "product_discount_net",
      }, // NETO
      {
        text: this.$t("accounting.product.discount.tax"),
        value: "product_discount_tax_value",
      }, // TOTAL IMPUESTOS
      {
        text: this.$t("accounting.product.discount.total"),
        value: "product_discount_total_with_tax",
      }, // TOTAL

      // GLOBAL DISCOUNTS
      { text: this.$t("accounting.discount.net"), value: "discount_net" }, // NETO
      { text: this.$t("accounting.discount.tax"), value: "discount_tax_value" }, // TOTAL IMPUESTOS
      {
        text: this.$t("accounting.discount.total"),
        value: "discount_total_with_tax",
      }, // TOTAL
    ];
  }

  get sSelected() {
    return this.sValue ? find(this.items, { value: this.sValue }) : null;
  }
}
</script>
