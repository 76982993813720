<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="item in items" :key="item.id">
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <module-actions
                  :value="item.id"
                  base-route-name="accountingentries"
                  @delete="deleteItem"
                />
                <span v-text="item.name" class="ml-4" />
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <accounting-entry-table :value="item" @delete="deleteItem" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountingentriesMixin from "@/modules/accountingentries/mixins/AccountingentriesMixin";
import type { AccountingEntryDataExt } from "@/types/utils";
import AccountingEntryTable from "@/modules/accountingentries/components/AccountingEntryTable.vue";

@Component({
  components: { AccountingEntryTable },
})
export default class AccountingEntriesList extends Mixins(
  AccountingentriesMixin
) {
  isList = true;

  get items() {
    return this.obCollection.getModelList() as Partial<AccountingEntryDataExt>[];
  }

  onMounted() {
    this.index();
  }
}
</script>
