import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import {
  AccountingEntry,
  AccountingEntryCollection,
} from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<AccountingEntry, AccountingEntryCollection>
>;

@Component
export default class AccountingentriesMixin extends Mixins(TypedModelMixin) {
  obCollection = new AccountingEntryCollection();
  obModelClass = AccountingEntry;
  sRoutePath = "/accountingentries";

  created() {
    this.onCreated();
  }
}
